import {
	AUTH_SUCCESS,
	CHANGE_PASSWORD,
	FETCH_TOKEN_INFO_FAILURE,
	FETCH_TOKEN_INFO_SUCCESS,
	LOGOUT,
	LOGOUT_FROM_ALL_DEVICES_FAILURE,
	LOGOUT_FROM_ALL_DEVICES_REQUEST,
	LOGOUT_FROM_ALL_DEVICES_SUCCESS,
	LOGOUT_FROM_ONE_DEVICES_FAILURE,
	LOGOUT_FROM_ONE_DEVICES_SUCCESS,
	SET_ID,
	SET_PARTNER,
	VERIFY_TOKEN_REQUEST,
} from "app/actionTypes";
import Cookies from "js-cookie";
import axios from "app/utils/http/http";
import { default as axiosIns } from "axios";
import compact from "lodash/compact";
import { getCredentials, getTokenIdFromToken } from "app/utils/auth";
import { getStore } from "app/configureStore";
import { COOKIES_AUTH_EXPIRES, REGISTER_URL, SIGNUP_URL } from "app/constants";
import env from "app/utils/env";
import {
	sendTagOnEmailSignin,
	sendTagOnEmailSignup,
	sendTagOnEmailSignupError,
	sendTagOnError,
	sendTagOnFacebookLogout,
	sendTagOnLogout,
	sendTagOnSponsorshipSubscribeFailed,
	sendTagOnSponsorshipSubscribeSuccess,
	sendTagOnTokenDeleteError,
	sendTagOnTokenRenewByPassed,
	sendTagOnTokenRenewError,
	sendTagOnTokenRenewSuccess,
} from "app/utils/analytics";
import get from "lodash/get";
import { updateEmail } from "app/reducers/emailActionCreators";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import {
	AUTH_GOOGLE,
	LOGIN,
	LOGIN_WITH_BOOKING_REFERENCE,
	RESEND_CONFIRMATION_EMAIL,
	RESET_PASSWORD,
	SET_CAN_LOGIN_AT,
	SET_SESSION_BIID,
	SET_USER_BIID,
} from "app/pages/Auth/AuthActionTypes";
import { STORAGE_KEY_PREFIX } from "app/utils/persistor";
import { getDefaultPartnerForDomain, getDefaultPartnerForShop } from "app/reducers/brandSelector";
import { isServerSide } from "app/utils/utils";
import { resetParentSponsorId } from "../Account/Parrainage/sponsorActionCreators";

const CHANGE_URL = "/auth/local/change";
const AUTH_GOOGLE_URL = "/auth/google";
const LOGIN_URL = "/auth/local/login";
const RESET_PASSWORD_URL = "/auth/local/reset";
const RESEND_URL = "/auth/local/resend";
const DELETE_TOKEN_URL = "/token/delete";
const INFO_TOKEN_URL = "/token/info";

export const sponsorshipSubscribe = (parentSponsorId, email, token) => {
	return axios.post(
		env("SPONSORSHIP_API_URL") + "/sponsorship/subscribe",
		{
			sponsorId: parentSponsorId,
			sponsored: email,
		},
		{
			headers: {
				...(token && { Authorization: token }),
				"Content-type": "application/json",
			},
		}
	);
};

export const authWithGoogle = data => {
	return {
		type: AUTH_GOOGLE,
		promise: axios.post(AUTH_GOOGLE_URL, data, {
			baseURL: env("USER_AUTH_API_URL"),
			headers: {
				"Content-type": "text/plain",
			},
		}),
	};
};

export const login = data => {
	return {
		type: LOGIN,
		promise: axios.post(
			LOGIN_URL,
			{ credentials: data },
			{
				baseURL: env("USER_AUTH_API_URL"),
				headers: {
					"Content-type": "text/plain",
				},
			}
		),
	};
};

export const resetPassword = payload => {
	return {
		type: RESET_PASSWORD,
		promise: axios.post(RESET_PASSWORD_URL, payload, {
			baseURL: env("USER_AUTH_API_URL"),
			headers: {
				"Content-type": "text/plain",
			},
		}),
	};
};

export const changePassword = (payload, queryToken) => {
	// Dans le cas ou on est dans la page de profile, on aura pas de token dans la queyr
	// Dans le cas de la page de changePassword, le token se trouve dans la query
	const shop = getStore()?.getState()?.shop;

	const authorization = queryToken || getCredentials(shop)?.token || false;

	return {
		type: CHANGE_PASSWORD,
		promise: new Promise((resolve, reject) => {
			const { token } = getCredentials(shop);
			const tokenId = getTokenIdFromToken(token);

			if (token && tokenId) {
				// suppression du tokenId, fire and forget
				axios
					.post(
						`${DELETE_TOKEN_URL}`,
						{ token: token, delete: [tokenId] },
						{
							baseURL: env("USER_AUTH_API_URL"),
							headers: {
								"Content-type": "text/plain",
							},
						}
					)
					.catch(error => {
						let message;

						if (error.response && error.response) {
							message = `${error.response.status} - ${JSON.stringify(
								error.response.data
							)}`;
						} else {
							message = JSON.stringify(error);
						}

						sendTagOnTokenDeleteError(message);
					});
			}

			axiosIns
				.post(CHANGE_URL, payload, {
					baseURL: env("USER_AUTH_API_URL"),
					headers: {
						Authorization: authorization,
						"Content-type": "application/json",
					},
				})
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		}),
	};
};

export const changePasswordSecurely = payload => {
	return {
		type: CHANGE_PASSWORD,
		promise: new Promise((resolve, reject) => {
			const shop = getStore()?.getState()?.shop;
			const { token } = getCredentials(shop);
			const tokenId = getTokenIdFromToken(token);

			if (token && tokenId) {
				// suppression du tokenId, fire and forget
				axios
					.post(
						`${DELETE_TOKEN_URL}`,
						{ token: token, delete: [tokenId] },
						{
							baseURL: env("USER_AUTH_API_URL"),
							headers: {
								"Content-type": "text/plain",
							},
						}
					)
					.catch(error => {
						let message;

						if (error.response && error.response) {
							message = `${error.response.status} - ${JSON.stringify(
								error.response.data
							)}`;
						} else {
							message = JSON.stringify(error);
						}

						sendTagOnTokenDeleteError(message);
					});
			}

			axios
				.post(
					`${LOGIN_URL}?changePassword=true`,
					{
						credentials: {
							email: payload.email,
							password: payload.actualPassword,
						},
					},
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(res => {
					axios
						.post(
							CHANGE_URL,
							{
								password: payload.password,
							},
							{
								baseURL: env("USER_AUTH_API_URL"),
								headers: {
									Authorization: res.data.token,
									"Content-type": "application/json",
								},
							}
						)
						.then(res => {
							resolve(res);
						});
				})
				.catch(err => {
					reject(err);
				});
		}),
	};
};

export const authSuccessForShop = ({ shop, partnerCode, token, uuid, email }) => {
	try {
		Cookies.set("auth", "auth", { expires: COOKIES_AUTH_EXPIRES, path: `/${shop}` });
		Cookies.set("partner", partnerCode, { path: `/${shop}` });
		// update the fsVisitorID cookie for flagship server side
		Cookies.set("fsVisitorID", uuid, { path: `/${shop}` });

		window.localStorage.setItem(
			`${STORAGE_KEY_PREFIX}:${shop}:auth`,
			JSON.stringify({
				token,
				uuid,
			})
		);

		if (email) {
			window.localStorage.setItem(`${STORAGE_KEY_PREFIX}:${shop}:email`, `"${email}"`);
		}
	} catch (e) {
		console.error("cookies set", e); // eslint-disable-line no-console
	}
};

export const authSuccess = (res, isSignup) => {
	const shop = getStore()?.getState()?.shop;

	const uuid = res?.data?.uuid;

	try {
		Cookies.set("authToken", res?.data?.token, {
			expires: COOKIES_AUTH_EXPIRES,
			path: `/${shop}`,
		});
		Cookies.set("auth", "auth", { expires: COOKIES_AUTH_EXPIRES, path: `/${shop}` });
		Cookies.set("partner", res.data.partner.code, { path: `/${shop}` });
		// update the fsVisitorID cookie for flagship server side
		Cookies.set("fsVisitorID", uuid, { path: `/${shop}` });

		// les test e2e cypress fails sans ce code. @see https://github.com/cypress-io/cypress/issues/2193
		if (window.Cypress) {
			Cookies.set("auth", "auth", {
				expires: COOKIES_AUTH_EXPIRES,
				path: `/${shop}`,
				domain: ".perfectstay.io",
			});
			Cookies.set("partner", res.data.partner.code, {
				path: `/${shop}`,
				domain: ".perfectstay.io",
			});
		}
	} catch (e) {
		// TODO
		console.error("cookies set", e); // eslint-disable-line no-console
	}
	return {
		type: AUTH_SUCCESS,
		isSignup,
		uuid,
		token: res.data.token, // TODO Quand on se loggue via le bloc membership, ca empeche le scroll de revenir sur le bloc membership. A regarder quand FBR-2575 sera en prod
		email: res.data.email,
		partner: res.data.partner,
		partners: getStore().getState().partners,
	};
};

/**
 * Verifie si le token passé en parametre contient un tokenId. En renvoie un nouveau avec tokenId si ce n'est pas le cas
 * @param token
 * @param dispatch
 * @returns {Promise}
 */
export const getCredentialsWithTokenId = token => {
	return (dispatch, getState) => {
		return new Promise(resolve => {
			const tokenId = getTokenIdFromToken(token);
			const shop = getState()?.shop;

			if (tokenId) {
				sendTagOnTokenRenewByPassed();
				resolve(token);
			} else if (token) {
				axios
					.post(
						"/token/renew",
						{ token: token },
						{
							baseURL: env("USER_AUTH_API_URL"),
							headers: {
								"Content-type": "text/plain",
							},
						}
					)
					.then(res => {
						const tokenWithTokenId = res.data.token;

						Cookies.set("authToken", tokenWithTokenId, {
							expires: COOKIES_AUTH_EXPIRES,
							path: `/${shop}`,
						});

						sendTagOnTokenRenewSuccess();

						resolve(tokenWithTokenId);
					})
					.catch(error => {
						let message;

						if (error.response && error.response) {
							message = `${error.response.status} - ${JSON.stringify(
								error.response.data
							)}`;
						} else {
							message = JSON.stringify(error);
						}

						sendTagOnTokenRenewError(message);
					});
			}
		});
	};
};

export const getTokenInfo = () => {
	return dispatch => {
		const shop = getStore()?.getState()?.shop;
		const { token } = getCredentials(shop);

		return new Promise((resolve, reject) => {
			axios
				.post(
					`${INFO_TOKEN_URL}`,
					{ token: token },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(res => {
					const tokenInfos = res.data;

					dispatch({
						type: FETCH_TOKEN_INFO_SUCCESS,
						tokenInfos,
					});

					resolve(tokenInfos);
				})
				.catch(error => {
					dispatch({
						type: FETCH_TOKEN_INFO_FAILURE,
					});

					reject(error);
				});
		});
	};
};

export const logoutFromDevice = tokenId => {
	return (dispatch, getState) => {
		const shop = getStore()?.getState()?.shop;
		const { token } = getCredentials(shop);
		return new Promise((resolve, reject) => {
			return axios
				.post(
					`${DELETE_TOKEN_URL}`,
					{ token: token, delete: [tokenId] },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(res => {
					const tokenInfos = res.data;
					const { token } = getCredentials(shop);
					const actualTokenId = getTokenIdFromToken(token);

					dispatch({
						type: LOGOUT_FROM_ONE_DEVICES_SUCCESS,
						tokenInfos,
					});

					if (actualTokenId === tokenId) {
						const shop = getState()?.shop;
						try {
							Cookies.remove("auth", { path: `/${shop}` });
							Cookies.remove("authToken", { path: `/${shop}` });
						} catch (e) {
							console.error("cookies set", e); // eslint-disable-line no-console
						} finally {
							dispatch({
								type: LOGOUT,
							});
						}
					}

					resolve(tokenInfos);
				})
				.catch(() => {
					dispatch({
						type: LOGOUT_FROM_ONE_DEVICES_FAILURE,
					});

					reject();
				});
		});
	};
};

export const logoutFromAllDevices = () => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const tokenIds = compact(
				getState().auth.tokenInfos.map(tokenInfo => tokenInfo.tokenId)
			);
			const { token } = getCredentials(getState()?.shop);

			dispatch({
				type: LOGOUT_FROM_ALL_DEVICES_REQUEST,
			});

			return axios
				.post(
					`${DELETE_TOKEN_URL}`,
					{ token: token, delete: tokenIds },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(() => {
					const shop = getState()?.shop;

					try {
						Cookies.remove("auth", { path: `/${shop}` });
						Cookies.remove("authToken", { path: `/${shop}` });
					} catch (e) {
						console.error("cookies set", e); // eslint-disable-line no-console
					} finally {
						dispatch({
							type: LOGOUT,
						});

						dispatch({
							type: LOGOUT_FROM_ALL_DEVICES_SUCCESS,
						});

						resolve();
					}
				})
				.catch(() => {
					dispatch({
						type: LOGOUT_FROM_ALL_DEVICES_FAILURE,
					});

					reject();
				});
		});
	};
};

export const logoutUser = () => {
	return {
		type: LOGOUT,
	};
};

export const logout = () => {
	return (dispatch, getState) => {
		const { token } = getCredentials();
		const shop = getState().shop;

		const domain = window?.location?.origin;

		const tokenId = getTokenIdFromToken(token);

		sendTagOnLogout();

		if (token && tokenId) {
			// suppression du tokenId, fire and forget
			axios
				.post(
					`${DELETE_TOKEN_URL}`,
					{ token: token, delete: [tokenId] },
					{
						baseURL: env("USER_AUTH_API_URL"),
						headers: {
							"Content-type": "text/plain",
						},
					}
				)
				.then(() => {
					Cookies.remove("auth", { path: `/${shop}` });
					Cookies.remove("authToken", { path: `/${shop}` });
				})
				.catch(error => {
					let message;

					if (error.response && error.response) {
						message = `${error.response.status} - ${JSON.stringify(
							error.response.data
						)}`;
					} else {
						message = JSON.stringify(error);
					}

					sendTagOnTokenDeleteError(message);
				});
		}

		const partnerToApply = !domain?.includes("localhost")
			? getDefaultPartnerForDomain(domain)(getState())
			: getDefaultPartnerForShop(getState());

		const partnerCodeToApply = partnerToApply?.code;

		if (
			!isServerSide &&
			window.FB?.getAccessToken() &&
			typeof window.FB?.logout === "function"
		) {
			window.FB.logout(response => {
				if (response?.status === "unknown") {
					sendTagOnFacebookLogout();
				}
			});
		}

		try {
			Cookies.remove("auth", { path: `/${shop}` });
			Cookies.set("partner", partnerCodeToApply, { path: `/${shop}` });

			// les test e2e cypress fails sans ce code. @see https://github.com/cypress-io/cypress/issues/2193
			if (!isServerSide && window.Cypress) {
				Cookies.remove("auth", { path: `/${shop}`, domain: ".perfectstay.io" });
				Cookies.set("partner", partnerCodeToApply, {
					path: `/${shop}`,
					domain: ".perfectstay.io",
				});
			}
		} catch (e) {
			// TODO
			console.error("cookies set", e); // eslint-disable-line no-console
		} finally {
			dispatch({
				type: SET_PARTNER,
				partner: partnerCodeToApply,
				strategy: partnerToApply?.strategy,
				partners: getState().partners,
			});

			dispatch({
				type: LOGOUT,
			});
		}
	};
};

export const setTokenFromPartner = tokenFromPartner => {
	const shop = getStore()?.getState()?.shop;
	Cookies.set("tokenFromPartner", tokenFromPartner, {
		expires: COOKIES_AUTH_EXPIRES,
		path: `/${shop}`,
	});
};

export const thunkSetId = uuid => {
	return (dispatch, getState) => {
		const actuelUuid = getState().auth.uuid;
		const shop = getState()?.shop;

		if (actuelUuid && actuelUuid !== uuid) {
			dispatch(logout());
		} else if (Cookies.get("auth", { path: `/${shop}` }) !== "auth") {
			try {
				Cookies.set("auth", "identified", {
					expires: COOKIES_AUTH_EXPIRES,
					path: `/${shop}`,
				});

				// les test e2e cypress fails sans ce code. @see https://github.com/cypress-io/cypress/issues/2193
				if (window.Cypress) {
					Cookies.set("auth", "identified", {
						expires: COOKIES_AUTH_EXPIRES,
						path: `/${shop}`,
						domain: ".perfectstay.io",
					});
				}
			} catch (e) {
				console.error("cookies set", e); // eslint-disable-line no-console
			}
		}

		dispatch({
			type: SET_ID,
			uuid,
		});
	};
};

export const signupPromise = ({
	data,
	navigate,
	partner,
	source,
	parentSponsorId,
	onAuthSuccess,
	dispatch,
	formHandle,
}) => {
	const { setErrors, setSubmitting } = formHandle;
	return new Promise((resolve, reject) => {
		return axios
			.post(
				SIGNUP_URL,
				{ credentials: data, partner, source },
				{
					baseURL: env("USER_AUTH_API_URL"),
					headers: { "Content-type": "text/plain" },
				}
			)
			.then(success => {
				dispatch(acceptCookyPolicy());

				return { success, isSignup: true };
			})
			.then(({ success }) => {
				if (parentSponsorId && partner.enableSponsorship) {
					sponsorshipSubscribe(parentSponsorId, data.email, success.data.token)
						.then(() => {
							sendTagOnSponsorshipSubscribeSuccess({
								sponsored: data.email,
								parentSponsorId: parentSponsorId,
							});
							return { success, isSignup: true };
						})
						.catch(err => {
							sendTagOnSponsorshipSubscribeFailed({
								sponsored: data.email,
								status: err.response.data.status || err.response.status,
							});
							return { success, isSignup: true };
						})
						.finally(() => {
							dispatch(resetParentSponsorId());
						});
				}

				return { success, isSignup: true };
			})
			.catch(error => {
				if (error.response.status === 403) {
					return axios
						.post(
							LOGIN_URL,
							{ credentials: data },
							{
								baseURL: env("USER_AUTH_API_URL"),
								headers: { "Content-type": "text/plain" },
							}
						)
						.then(success => {
							dispatch(acceptCookyPolicy());
							return { success, isSignup: false };
						});
				}
				throw error;
			})
			.then(({ success, isSignup }) => {
				dispatch(authSuccess(success, isSignup));

				if (onAuthSuccess && typeof onAuthSuccess === "function") {
					onAuthSuccess(success);
				} else {
					navigate("/listing"); // TODO pour le cas /home/signup continue de fonctionner. A refactorer en utilisant authSuccess
				}

				if (isSignup) {
					sendTagOnEmailSignup();
				} else {
					sendTagOnEmailSignin();
				}

				resolve();
			})
			.catch(error => {
				if (Object.prototype.hasOwnProperty.call(error, "_error")) {
					sendTagOnEmailSignupError({ status: "", error: error._error.id });
					sendTagOnError(error._error.id);
					setSubmitting(false);
					setErrors({ _error: { id: error._error.id } });
					reject(error);
				}

				let _error = { id: "error.generic" };

				if (
					error.response &&
					(error.response.status === 403 || error.response.status === 401)
				) {
					_error = { id: "error.email.not.available" };
				}

				sendTagOnEmailSignupError({
					status: error.response && error.response.status,
					error: _error.id,
				});
				setSubmitting(false);
				setErrors({ _error: _error });
				reject({ _error });
			});
	});
};

export const registerPromise = ({
	data,
	partner,
	navigate,
	dispatch,
	shop,
	onAuthSuccess,
	formHandle,
}) => {
	const { setErrors, setSubmitting } = formHandle;
	return new Promise((resolve, reject) => {
		return axios
			.post(
				REGISTER_URL,

				{
					credentials: data,
					redirect: `${env("BASE_URL")}/${shop}/confirmRegistration`,
				},
				{
					baseURL: env("USER_AUTH_API_URL"),
					headers: { "Content-type": "text/plain" },
				}
			)
			.then(() => {
				dispatch(acceptCookyPolicy());
				dispatch(updateEmail(data.email));
				sendTagOnEmailSignup(); // on n'envoie le tag qu'une seule fois (lorsqu'il s'inscrit la première fois

				navigate("./registration-succeeded");
			})
			.catch(error => {
				if (error.response.status === 403) {
					/**
					 * présent base user (registered + confirmed)
					 * teasing : affichage date ouverture vente
					 * post-teasing:redirection listing
					 **/
					const today = new Date();
					const teasingEndDate = new Date(get(partner, "teasingEndDate"));
					if (today > teasingEndDate) {
						return axios
							.post(
								LOGIN_URL,
								{ credentials: data },
								{
									baseURL: env("USER_AUTH_API_URL"),
									headers: { "Content-type": "text/plain" },
								}
							)
							.then(res => {
								dispatch(acceptCookyPolicy());
								dispatch(authSuccess(res));

								if (onAuthSuccess && typeof onAuthSuccess === "function") {
									onAuthSuccess(res);
								}
								sendTagOnEmailSignin(); // on n'envoie le tag qu'une seule fois (lorsque les ventes seront ouvertes seulement)
								navigate(`/listing?uuid=${res.data.uuid}`);
							})
							.catch(error => {
								if (Object.prototype.hasOwnProperty.call(error, "_error")) {
									sendTagOnError(error._error.id);
									reject(error);
								}

								let _error = { id: "error.generic" };

								if (
									error.response &&
									(error.response.status === 403 || error.response.status === 401)
								) {
									_error = { id: "error.email.not.available" };
								}

								setSubmitting(false);
								setErrors({ _error: _error });
								sendTagOnError(_error.id);
								reject({ _error });
							});
					}

					navigate("./registration-confirmed");
				} else if (error.response.status === 409) {
					/**
					 * présent base optin (registered + not confirmed)
					 * reste sur la page mais affiche message avec renvoi de mail
					 */

					dispatch(updateEmail(data.email));

					navigate("./registration-not-confirmed");
				}
				throw error;
			});
	});
};

export const resendConfirmationMail = ({ email, shop }) => {
	return {
		type: RESEND_CONFIRMATION_EMAIL,
		promise: axios.post(
			RESEND_URL,
			{ email, redirect: `${env("BASE_URL")}/${shop}/confirmRegistration` },
			{
				baseURL: env("USER_AUTH_API_URL"),
				headers: { "Content-type": "text/plain" },
			}
		),
	};
};

export const loginWithBookingReference = payload => {
	return {
		type: LOGIN_WITH_BOOKING_REFERENCE,
		promise: axios.post(
			"/auth/local/login",
			{ credentials: payload },
			{
				baseURL: env("USER_AUTH_API_URL"),
				headers: {
					"Content-type": "text/plain",
				},
			}
		),
	};
};

export const setUserBiid = userBiid => {
	return {
		type: SET_USER_BIID,
		userBiid,
	};
};

export const setSessionBiid = (sessionBiid, sessionBiidExpirationDate) => {
	return {
		type: SET_SESSION_BIID,
		sessionBiid,
		sessionBiidExpirationDate,
	};
};

export const setCanLoginAt = canLoginAt => {
	return {
		type: SET_CAN_LOGIN_AT,
		canLoginAt,
	};
};

export const verifyToken = token => {
	return {
		type: VERIFY_TOKEN_REQUEST,
		promise: axios.post(
			"/token/verify",
			{ token: token },
			{
				baseURL: env("USER_AUTH_API_URL"),
				headers: {
					"Content-type": "text/plain",
				},
			}
		),
	};
};
