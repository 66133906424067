import PerfectApp from "./PerfectApp.jsx";
import PropTypes from "prop-types";
import { getCredentialsWithTokenId, setSessionBiid, setUserBiid } from "app/pages/Auth/AuthActions";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import Cookies from "js-cookie";
import {
	BRANDS,
	COOKIES_AUTH_EXPIRES,
	DATADOG_ERROR_BLACKLIST,
	DATADOG_RESOLUTIONS,
	FS_RESOLUTIONS,
	SESSION_BIID_EXPIRATION_MAX_AGE,
	STRATEGY,
} from "app/constants";
import { isWhiteLabel } from "app/reducers/brandSelector";
import { getBrandData, getPartnerData } from "app/reducers/partnerActionCreators";
import { getUUID, isAuthenticated } from "app/pages/Auth/authSelectors";
import { brandPropTypes, resolutionPropType, strategyShape } from "app/utils/propTypes";
import {
	fetchCoupons,
	fetchCreditCampaign,
} from "app/pages/Account/MyCoupons/couponActionCreators";
import AppGlobalsContext from "app/AppGlobalsContext";
import { fetchPaymentMethods } from "app/pages/Booking/Payment/paymentActionCreators";
import { v4 as uuidv4 } from "uuid";
import { isExpiredAfter } from "app/utils/utils";
import { CacheStrategy, FlagshipProvider, LogLevel } from "@flagship.io/react-sdk";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import {
	finishLoadingBar,
	showLoadingBar,
} from "app/pages/.shared/LoadingBar/loadingBarActionCreators";
import { useLocation } from "react-router-dom";
import useVirtualPageLoading from "app/utils/analytics/useVirtualPageLoading";
import { getPaymentDeltaBasePrice } from "app/pages/Booking/bookingSelectors";
import installMarkerIOCodeSnippet from "app/utils/markerio/installMarkerIOCodeSnippet";
import { updateTheme } from "app/reducers/themeActionCreators";
import { parseQueries } from "app/utils/routerUtils";
import { datadogRum } from "@datadog/browser-rum";
import { getCalendarStressMarketingMessagesMapping } from "app/pages/StressMarketing/stressMarketingSelector";
import { isEmpty } from "lodash";
import { isFlashsale } from "app/pages/FicheProduit/ficheProduitSelector";
import { getCredentials } from "./utils/auth.js";

const PerfectAppContainer = props => {
	const {
		enableCredits,
		enableCreditNotes,
		isAuthenticated,
		fetchCoupons,
		strategy,
		brand,
		getCredentialsWithTokenId,
		getPartnerData,
		shop,
		resolution,
		fetchPaymentMethods,
		partnerCode,
		setSessionBiid,
		setUserBiid,
		sessionBiid,
		userBiid,
		sessionBiidExpirationDate,
		productsVisibility,
		envVars = {},
		uuid,
		showLoadingBar,
		deltaBasePrice,
		finishLoadingBar,
		fetchCreditCampaign,
		offerType,
		initialFsVisitorId = {},
		initialFlagsData = {},
		email,
		stressMarketingMsg,
	} = props;

	const { track } = useAnalytics();

	useVirtualPageLoading();

	installMarkerIOCodeSnippet({ env: envVars?.ENVIRONMENT });

	const { pathname, search } = useLocation();

	const query = parseQueries(search);

	const [prevLoc, setPrevLoc] = useState("");

	// https://dev.to/ataparvinghods/react-progress-bar-on-route-changing-both-next-cra-3d27
	useEffect(() => {
		setPrevLoc(pathname);
		showLoadingBar();
		if (pathname === prevLoc) {
			setPrevLoc("");
		}
	}, [pathname]);

	useEffect(() => {
		finishLoadingBar();
	}, [prevLoc]);

	useEffect(() => {
		const auth = Cookies.get("auth");

		// si un ancien cookie auth est présent ...
		if (auth) {
			// ... on le met dans le cookie fr-FR
			Cookies.remove("auth");
			Cookies.set("auth", auth, { expires: COOKIES_AUTH_EXPIRES, path: "/fr-FR" });
		}

		const partner = Cookies.get("partner");

		if (partner) {
			Cookies.remove("partner");
			Cookies.set("partner", partner, { path: "/fr-FR" });
		}

		getPartnerData();

		fetchPaymentMethods();

		if (
			!sessionBiid ||
			isExpiredAfter(sessionBiidExpirationDate, SESSION_BIID_EXPIRATION_MAX_AGE)
		) {
			setSessionBiid(`${partnerCode}-${uuidv4()}`, Date.now());
		}

		if (!userBiid) {
			setUserBiid(`${partnerCode}-${uuidv4()}`);
		}
	}, []);

	useEffect(() => {
		if (brand && shop) {
			fetchCreditCampaign({ shop, brandCode: brand });
		}
	}, [brand, shop]);

	useEffect(() => {
		if (brand && envVars?.ENVIRONMENT?.includes("live")) {
			datadogRum.init({
				applicationId: "b4c3deca-9231-4964-9eab-ae079a59d709",
				clientToken: "pub98922e8d55e1a63290a9cef627653e47",
				site: "datadoghq.eu",
				service: `front-${brand}`,
				actionNameAttribute: "data-testid",
				env: envVars?.ENVIRONMENT,
				version: window?.__VERSION__,
				sessionSampleRate: 10,
				sessionReplaySampleRate: 50,
				trackInteractions: true,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
				trackFrustrations: true,
				defaultPrivacyLevel: "mask-user-input",
				trackViewsManually: true,
				// allowedTracingUrls: [/https:\/\/[a-z]*\.connectivity\.[a-z]*\.perfectstay\.io/],
				// eslint-disable-next-line consistent-return
				beforeSend: event => {
					if (event.type === "error") {
						const errorMessage = event.error?.message;
						const stackTrace = event.error?.stack;

						// Check if the error message matches any regex pattern in the blacklist
						const shouldFilter = DATADOG_ERROR_BLACKLIST.some(pattern => {
							return pattern.test(errorMessage) || pattern.test(stackTrace);
						});

						return !shouldFilter;
					}
				},
			});

			datadogRum.startSessionReplayRecording();

			datadogRum.setGlobalContextProperty("global", {
				brand,
				shop,
				partnerCode,
				resolution: DATADOG_RESOLUTIONS[resolution],
				strategy,
			});
		}
	}, [brand, shop, partnerCode, strategy, envVars, resolution]);

	useEffect(() => {
		if (isAuthenticated) {
			datadogRum.setUser({
				id: uuid || userBiid,
				partnerCode,
				brand,
				shop,
			});
		}
	}, [isAuthenticated, uuid, email, partnerCode, brand, shop]);

	useEffect(() => {
		// Les nouveaux tokens disposent désormais d'un tokenId.
		// Au démarrage de l'appli, on verifie si le token dispose d'un tokenId
		// si ce n'est pas le cas, alors on en regénère un nouveau avec tokenId et on remplace l'ancien
		// Seulement ppour les brands avec api membre
		if (strategy === STRATEGY.SIGNUP_FIRST && brand !== BRANDS.XX) {
			const token = props.token;
			if (token) {
				getCredentialsWithTokenId(token);
			}
		}
	}, [strategy]);

	useEffect(() => {
		if ((enableCredits || enableCreditNotes) && isAuthenticated) {
			fetchCoupons();
		}
	}, [enableCredits, enableCreditNotes, isAuthenticated]);

	const handleOnUserExposure = ({ fromFlag = {} }) => {
		const currentPathname = pathnameRef.current;
		if (fromFlag.key) {
			track("user_exposed_to_abtest", {
				abtest: {
					abTestName: fromFlag.metadata?.campaignName,
					variationName: fromFlag.metadata?.variationName,
					campaignType: fromFlag.metadata?.campaignType,
					flagName: fromFlag.key,
					flagValue: fromFlag.value,
					currentPathname: currentPathname, // Utilise la valeur capturée de pathname
				},
			});
		}
	};

	const pathnameRef = useRef(pathname); // Crée une référence interne initialisée avec la valeur de pathname

	useEffect(() => {
		pathnameRef.current = pathname; // Met à jour la référence interne lorsque pathname change
	}, [pathname]);

	const fsVisitorID = Cookies.get("fsVisitorID");

	return (
		<AppGlobalsContext.Provider
			value={{
				brand: brand,
				shop,
				resolution,
				strategy,
				partnerCode,
				productsVisibility,
				envVars,
			}}
		>
			<FlagshipProvider
				logLevel={LogLevel.NONE}
				envId={envVars.FLAGSHIP_ENV_ID}
				apiKey={envVars.FLAGSHIP_APP_KEY}
				onVisitorExposed={handleOnUserExposure}
				trackingManagerConfig={{
					cacheStrategy: CacheStrategy.CONTINUOUS_CACHING, // @see https://docs.developers.flagship.io/docs/react-v3-2-x#cachestrategy
					poolMaxSize: 10, // Define the minimum number of hits the pool must reach to automatically batch all hits in the pool and send it
					batchIntervals: 5, // Define a regular interval in seconds to trigger batch processing
				}}
				visitorData={{
					id: uuid || fsVisitorID || userBiid || initialFsVisitorId,
					context: {
						resolution: FS_RESOLUTIONS[resolution],
						brand,
						shop,
						strategy,
						partnerCode,
						deltaBasePrice,
						offerType: offerType || query.offerType, // in case offerType came from quotation url
						stressMarketingMsg: !isEmpty(stressMarketingMsg),
					},
					isAuthenticated,
				}}
				initialFlagsData={initialFlagsData}
			>
				<PerfectApp {...props} />
			</FlagshipProvider>
		</AppGlobalsContext.Provider>
	);
};

PerfectAppContainer.propTypes = {
	envVars: PropTypes.object,
	productsVisibility: PropTypes.string,
	fetchCoupons: PropTypes.func,
	getPartnerData: PropTypes.func,
	getBrandData: PropTypes.func,
	getCredentialsWithTokenId: PropTypes.func,
	fetchPaymentMethods: PropTypes.func,
	token: PropTypes.string,
	shop: PropTypes.string,
	resolution: resolutionPropType,
	brand: brandPropTypes,
	strategy: strategyShape,
	isAuthenticated: PropTypes.bool,
	enableCredits: PropTypes.bool,
	enableCreditNotes: PropTypes.bool,
	partnerCode: PropTypes.string,
	setSessionBiid: PropTypes.func,
	registerExposedAbTest: PropTypes.func,
	sessionBiid: PropTypes.string,
	sessionBiidExpirationDate: PropTypes.number,
	setUserBiid: PropTypes.func,
	userBiid: PropTypes.string,
	initialFsVisitorId: PropTypes.object,
	initialFlagsData: PropTypes.object,
	showLoadingBar: PropTypes.func,
	finishLoadingBar: PropTypes.func,
	uuid: PropTypes.string,
	deltaBasePrice: PropTypes.number,
	email: PropTypes.string,
	fetchCreditCampaign: PropTypes.func,
	offerType: PropTypes.string,
	updateTheme: PropTypes.func,
	stressMarketingMsg: PropTypes.object,
};

const mapStateToProps = state => {
	const { token } = getCredentials(state.shop);
	return {
		token: token,
		uuid: getUUID(state),
		deltaBasePrice: getPaymentDeltaBasePrice(state),
		offerType: state?.booking?.offer?.type,
		stressMarketingMsg: getCalendarStressMarketingMessagesMapping(state),
		sessionBiidExpirationDate: state.auth.sessionBiidExpirationDate,
		sessionBiid: state.auth.sessionBiid,
		userBiid: state.auth.userBiid,
		shop: state.shop,
		brand: state.brand.code,
		strategy: state.partner.strategy,
		isFlashSale: isFlashsale(state),
		enableCredits: state.partner.enableCredits,
		enableCreditNotes: state.partner.enableCreditNotes,
		isAuthenticated: isAuthenticated(state),
		isWhiteLabel: isWhiteLabel(state),
		productsVisibility: state.productsVisibility,
		partnerCode: state.partner.code,
		envVars: state.envVars,
		showSmartDPOnRootUrl: state.partner.showSmartDPOnRootUrl,
		resolution: state.resolution,
		email: state.email,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			getCredentialsWithTokenId,
			getPartnerData,
			getBrandData,
			fetchCoupons,
			fetchPaymentMethods,
			setSessionBiid,
			setUserBiid,
			showLoadingBar,
			finishLoadingBar,
			fetchCreditCampaign,
			updateTheme,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PerfectAppContainer);
