import {
	fetchFicheProduit,
	fetchFicheProduitUri,
	fetchFirstProducts,
} from "app/pages/Listing/productActionCreators";
import {
	fetchMerchandisingLight,
	fetchMerchandisings,
} from "app/pages/Merchandising/merchandisingActionCreators";
import { getLandingPagesAuth } from "app/pages/LandingPage/Auth/LandingAuthActionCreators";
import { fetchSDPAccommodationDescription } from "app/pages/SmartDP/smartDPActionCreators";

export const getRoutes = shop => [
	{
		path: `/${shop}/home`,
	},
	{
		path: `/${shop}/home/login`,
	},
	{
		path: `/${shop}/home/signup`,
	},
	{
		path: `/${shop}/auth`,
	},
	{
		path: `/${shop}/giveaway`,
	},
	{
		path: `/${shop}/unsubscribe`,
	},
	{
		path: `/${shop}/account`,
	},
	{
		path: `/${shop}/account/profile`,
	},
	{
		path: `/${shop}/account/customer-request/:request`,
	},
	{
		path: `/${shop}/account/mybookings/:bookingReference`,
	},
	{
		path: `/${shop}/account/mybookings`,
	},
	{
		path: `/${shop}/account/security`,
	},
	{
		path: `/${shop}/account/subscriptions`,
	},
	{
		path: `/${shop}/account/sponsorship`,
	},
	{
		path: `/${shop}/account/mycredits`,
	},
	{
		path: `/${shop}/account/myrefunds`,
	},
	{
		path: `/${shop}/account/faq-contact`,
	},
	{
		path: `/${shop}/landing-page/:operationCode`,
		ssrActionCreators: [getLandingPagesAuth],
	},
	{
		path: `/${shop}/schizophrene`,
	},
	{
		path: `/${shop}/priceMatchGuarantee`,
	},
	{
		path: `/${shop}/booking`,
	},
	{
		path: `/${shop}/external-redirect`,
	},
	{
		path: `/${shop}/confirmation`,
	},
	{
		path: `/${shop}/not-found`,
	},
	{
		path: `/${shop}/technical-error`,
	},
	{
		path: `/${shop}/technical-error/registration-confirmation-expired`,
	},
	{
		path: `/${shop}/technical-error/confirm-registration-failed`,
	},
	{
		path: `/${shop}/technical-error/sso`,
	},
	{
		path: `/${shop}/technical-error/duplicate-booking`,
	},
	{
		path: `/${shop}/mybookings`,
	},
	{
		path: `/${shop}/magazine`,
	},
	{
		path: `/${shop}/lp`,
	},
	{
		path: `/${shop}/listing`,
		ssrActionCreators: [fetchFirstProducts, fetchMerchandisings],
	},
	{
		path: `/${shop}/merch`,
		ssrActionCreators: [fetchMerchandisingLight],
	},
	{
		path: `/${shop}/sdp/search`,
	},
	{
		path: `/${shop}/sdp/listing`,
	},
	{
		path: `/${shop}/sdp/booking/quote`,
	},
	{
		path: `/${shop}/sdp/booking/payment`,
	},
	{
		path: `/${shop}/sdp/booking/confirmation`,
	},
	{
		path: `/${shop}/sdp/hotelDescription/:accommodationId/:code`,
		ssrActionCreators: [fetchSDPAccommodationDescription],
	},
	{
		path: `/${shop}/product/id/:productId`,
		ssrActionCreators: [fetchFicheProduitUri],
	},
	{
		path: `/${shop}/booking/quote`,
	},
	{
		path: `/${shop}/booking/payment`,
	},
	{
		path: `/${shop}/booking/confirmation`,
	},
	{
		path: `/${shop}/booking-auth`,
	},
	{
		path: `/${shop}/booking-search`,
	},
	{
		path: `/${shop}/ourcommitments`,
	},
	{
		path: `/${shop}/faq`,
	},
	{
		path: `/${shop}/faq/category/:categoryId`,
	},
	{
		path: `/${shop}/faq/category/:categoryId/subcategory/:subCategoryId`,
	},
	{
		path: `/${shop}/faq/category/:categoryId/subcategory/:subCategoryId/article/:articleId`,
	},
	{
		path: `/${shop}/page/:slug`,
	},
	{
		path: `/${shop}/:productUri`, // have to be the last route ou that array
		ssrActionCreators: [fetchFicheProduit],
	},
	{
		path: `/${shop}`,
		ssrActionCreators: [fetchFirstProducts, fetchMerchandisings],
	},
];
