import {
	calculateAgeFromBirthday,
	getTwoDigitsDate,
	getUTCDateForAnalytics,
} from "app/utils/utils";
import defaultsDeep from "lodash/defaultsDeep";
import {
	calculateActivitiesUpgradePrice,
	getBoardUpgrade,
	getBookingFeesAmount,
	getFlightUpgrade,
	getInsuranceFlex,
	getQuoteTotalWithDelta,
	getRoomUpgrade,
	getTransferUpgrade,
} from "app/pages/Booking/bookingSelectors";
import env from "app/utils/env";
import { PASSENGER_TYPE } from "app/constants";
import { getSiteSection } from "app/utils/urlDataExtractor";
import get from "lodash/get";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
	getVoucherTypesForAnalytics,
} from "app/pages/Account/MyCoupons/couponSelector";
import { parse } from "qs";

const INITIAL_STRINGIFIED_VIRTUAL_PAGE = {
	environment: "",
	shop: "",
	pageTitle: "",
	siteSection: "",
	brand: "",
	partner: "",
	navigationStrat: "",
};

const INITIAL_STRINGIFIED_PRODUCT = {
	id: "",
	name: "",
	category: "",
	reduction: "",
	expiration: {
		day: "",
		hour: "",
	},
	country: "",
	resort: "",
	productOwner: "",
	region: "",
	expert: {
		name: "",
	},
	endAt: "",
	endAtDate: "",
	propertyType: "",
	contractType: "",
	tripAdvisor: {
		rating: "",
	},
};

const INITIAL_STRINGIFIED_PREPACKAGE = {
	offer: { type: "" },
	price: "",
	duration: { value: "" },
	departureDate: "",
	departureCity: { label: "" },
	adults: "",
	infants: "",
	children: "",
	childrenBirthdates: "",
	infantsPassengers: "",
};

const INITIAL_STRINGIFIED_QUOTATION = {
	connectivitySessionId: "",
	totalBookingRevenue: "",
	cabin: "",
	board: "",
	roomUpgradePrice: "",
	flightUpgradePrice: "",
	boardUpgradePrice: "",
	bookingFees: "",
};

const INITIAL_STRINGIFIED_PAYMENT = {
	hasFormErrors: "",
	insuranceName: "",
	formErrors: "",
	paymentMode: "",
	paymentType: "",
	bookingReference: "",
};

export const mapPageStoreToTag = ({ pathname = "", search = "", state = {} }) => {
	let pageTitleToSend = pathname;

	const query = parse(search, {
		ignoreQueryPrefix: true,
	});

	const landingPageTopic = query.topic;

	if (landingPageTopic) {
		pageTitleToSend = pageTitleToSend + landingPageTopic;
	}

	const { shop } = state;
	return defaultsDeep(
		{
			shop,
			pageTitle: pageTitleToSend,
			siteSection: getSiteSection(pageTitleToSend, shop),
			navigationStrat: state.partner.strategy,
			brand: env("BRAND"),
			partner: state.partner.code,
		},
		INITIAL_STRINGIFIED_VIRTUAL_PAGE
	);
};

export const mapUserStoreToTag = state => {
	const mapAge = () => {
		const _birthDate = state.profile && state.profile.birthDate;
		return !_birthDate ? "" : calculateAgeFromBirthday(_birthDate).toString();
	};

	const creditsAmount = getAvailableCreditsTotal(state);
	const creditNotesAmount = getAvailableCreditNotesTotal(state);

	const user = {
		id: (state.auth && state.auth.uuid) || "",
		email: state.email || "",
		gender: (state.profile && state.profile.name && state.profile.name.gender) || "",
		age: mapAge(),
		session_biid: get(state, "auth.sessionBiid", ""),
		user_biid: get(state, "auth.userBiid", ""),
		source: (state.marketing && state.marketing.source) || "",
		engagementLvl: "",
		creditValue: `${creditsAmount}`,
		refundValue: `${creditNotesAmount}`,
		voucherTypes: getVoucherTypesForAnalytics(state),
		utm_medium: state.marketing?.utmMedium || "",
		utm_source: state.marketing?.utmSource || "",
		utm_campaign: state.marketing?.utmCampaign || "",
		utm_content: state.marketing?.utmContent || "",
		phone: state.profile?.address?.phone || "",
		partnerData: state.partnerData || "",
		utm_term: state.marketing?.utmTerm || "",
	};

	if (state.type) {
		user.type = state.type;
	}

	if (state.status) {
		user.status = state.status;
	}

	return user;
};

export const mapProductToTag = (ficheProduitData, position) => {
	const ficheProduit = defaultsDeep(ficheProduitData, INITIAL_STRINGIFIED_PRODUCT);
	const date = ficheProduit.endAt && new Date(ficheProduit.endAt);
	const productTag = {
		id: String(ficheProduit.id),
		name: ficheProduit.name,
		category: String(ficheProduit.category),
		reduction: ficheProduit.fromPriceType ? String(ficheProduit.fromPriceType.value) : "",
		endAt:
			ficheProduit.expiration.day && ficheProduit.expiration.hour
				? `day: ${ficheProduit.expiration.day} hour: ${ficheProduit.expiration.hour}`
				: "",
		endAtDate: date
			? `${date.getUTCFullYear()}-${getTwoDigitsDate(
					date.getUTCMonth() + 1
			  )}-${getTwoDigitsDate(date.getUTCDate())}`
			: "",
		country: ficheProduit.country,
		propertyType: ficheProduit.propertyType,
		saleId: ficheProduit.saleId,
		resort: ficheProduit.resort,
		region: ficheProduit.region,
		isFlashsale: ficheProduit.isFlashsale,
		sourceDescription: ficheProduit.sourceDescription,
	};

	if (position !== undefined) {
		productTag.position = String(position);
	}

	return productTag;
};

export const mapFicheProduitStoreToTag = ficheProduitFromStore => {
	const ficheProduit = defaultsDeep(ficheProduitFromStore, INITIAL_STRINGIFIED_PRODUCT);

	const ficheProduitTag = mapProductToTag(ficheProduit);

	return Object.assign({}, ficheProduitTag, {
		productOwner: ficheProduitFromStore.expert.name,
		propertyType: ficheProduitFromStore.propertyType,
		contractType: ficheProduitFromStore.contractType,
		tripAdvisorRating: String(ficheProduitFromStore.tripAdvisor.rating),
	});
};

export const mapPrePackageStoreToTag = state => {
	const booking = defaultsDeep(state.booking, INITIAL_STRINGIFIED_PREPACKAGE);

	const childrenPassengers = booking.passengers
		? booking.passengers.filter(passenger => passenger.type === PASSENGER_TYPE.CHILD)
		: [];
	const infantsPassengers = booking.passengers
		? booking.passengers.filter(passenger => passenger.type === PASSENGER_TYPE.INFANT)
		: [];

	const childrenBirthdates =
		childrenPassengers.length > 0 && state.ficheProduit.isChildAgeRequired
			? childrenPassengers.map(passenger => JSON.stringify(passenger.birthdate)).toString()
			: "";
	const infantsBirthdates =
		infantsPassengers.length > 0 && state.ficheProduit.isChildAgeRequired
			? infantsPassengers.map(passenger => JSON.stringify(passenger.birthdate)).toString()
			: "";

	const bestPriceOfSelectedDuration = get(booking, "duration.minPrice.p", "");

	return {
		offerType: booking.offer.type,
		bestPrice: `${bestPriceOfSelectedDuration}`,
		defaultPrice: String(booking.price),
		duration: String(booking.duration.value),
		departureDate: booking.departureDate ? getUTCDateForAnalytics(booking.departureDate) : "",
		departureCity: booking.departureCity.label,
		adults: String(booking.adults),
		infants: String(booking.infants),
		children: String(booking.children),
		childrenBirthdates,
		infantsBirthdates,
	};
};

export const mapQuoteStoreToTag = state => {
	const isAssuranceFlexSelect = getInsuranceFlex(state)
		? getInsuranceFlex(state).code !== "STANDARD"
		: false;
	const quoteFromStore = {
		totalBookingRevenue: String(getQuoteTotalWithDelta(state)),
		connectivitySessionId: get(state, "quotation.code", ""),
		roomUpgradePrice: getRoomUpgrade(state) ? String(getRoomUpgrade(state).upgradePrice) : "",
		flightUpgradePrice: getFlightUpgrade(state)
			? String(getFlightUpgrade(state).upgradePrice)
			: "",
		transferUpgradePrice: getTransferUpgrade(state)
			? String(getTransferUpgrade(state).upgradePrice)
			: "",
		boardUpgradePrice: getBoardUpgrade(state)
			? String(getBoardUpgrade(state).upgradePrice)
			: "",
		activitiesUpgradePrice: calculateActivitiesUpgradePrice(state)
			? String(calculateActivitiesUpgradePrice(state))
			: "",
		bookingFees: String(getBookingFeesAmount(state)),
		cabin:
			state.booking.flight && state.booking.flight.outbound
				? state.booking.flight.outbound.cabin
				: "",
		board: state.booking.board.label,
		flex_upgrade_price: getInsuranceFlex(state)
			? String(getInsuranceFlex(state).upgradePrice)
			: "",
		flex_selected: String(isAssuranceFlexSelect),
	};

	const quote = defaultsDeep(quoteFromStore, INITIAL_STRINGIFIED_QUOTATION);
	return {
		connectivitySessionId: quote.connectivitySessionId,
		totalBookingRevenue: quote.totalBookingRevenue,
		roomUpgradePrice: quote.roomUpgradePrice,
		flightUpgradePrice: quote.flightUpgradePrice,
		transferUpgradePrice: quote.transferUpgradePrice,
		cabin: quote.cabin,
		boardUpgradePrice: quote.boardUpgradePrice,
		board: quoteFromStore.board,
		applicationFee: quote.bookingFees,
		activitiesUpgradePrice: quote.activitiesUpgradePrice,
		flex_upgrade_price: quoteFromStore.flex_upgrade_price,
		flex_selected: quoteFromStore.flex_selected,
	};
};

export const mapPaymentStoreToTag = paymentData => {
	const payment = defaultsDeep(paymentData, INITIAL_STRINGIFIED_PAYMENT);

	return {
		insuranceName: payment.insuranceName,
		hasFormErrors: String(payment.hasFormErrors),
		formErrors: payment.formErrors,
		paymentMode: payment.paymentMode,
		paymentType: payment.paymentType,
		bookingReference: payment.bookingReference,
	};
};

export const mapPaymentTypesStoreToTag = paymentTypesData => {
	const paymentTypes = paymentTypesData.map(payType => {
		const {
			type = "",
			acceptedCreditCardTypes = "",
			percentagePayNow = "",
			paymentMethod = "",
			terms = "",
			paymentType = "",
		} = payType;
		return {
			type,
			acceptedCreditCardTypes,
			percentagePayNow: percentagePayNow.toString(),
			paymentMethod,
			terms: terms.toString(),
			paymentType,
		};
	});

	return paymentTypes;
};

/**
 * return true if url pathame contains "sdp"
 * root url if showSmartDPOnRootUrl = true should also return true because root url displays
 * the sdp search form
 */
export const checkIfSDPPages = (state = {}, pathname = "") => {
	const { shop, partner = {} } = state;
	const pathNameParts = pathname.split(`/${shop}`);
	const isRootUrl = pathNameParts[1] === "" || pathNameParts[1] === "/";
	return (isRootUrl && partner.showSmartDPOnRootUrl) || pathname.includes("sdp");
};
