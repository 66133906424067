import { BRANDS } from "app/constants";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { IconCreditHeader } from "app/pages/.shared/static/icons";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import AccountMenuContainer from "app/pages/Header/AccountMenu/AccountMenuContainer";
import { AsyncSmartDPSearchFormContainer } from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import { sendTagOnContactCall } from "app/utils/analytics";
import useOrangeHeader from "app/utils/hooks/useOrangeHeader";
import { brandPropTypes } from "app/utils/propTypes";
import { checkIfComingFromRemarketingEmail } from "app/utils/urlDataExtractor";
import { displayable } from "app/utils/utils";
import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import "./Header.scss";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import ShopSwitcher from "app/pages/Home/ShopSwitcher/ShopSwitcher";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const Header = ({
	brand,
	isMobile = false,
	userIsConnected = false,
	enableCredits = false,
	showShopSwitcher = false,
	fallbackRedirectUrl,
	merchandisingCode,
	operatorEmail,
	dialPhone,
	creditsAmount = 0,
	onClickBackBtn = () => {},
	handleSDPFormSubmit = () => {},
	showMenuItems = true,
	showDialPhone = false,
	showBackBtn = false,
	displayLoader,
	disabledHomeRedirect = false,
	inscriptionCreditCampaign = {},
	isTransparentHeader = false,
}) => {
	const { pathname } = useLocation();
	const { productUri } = useParams();
	const [searchParams] = useSearchParams();

	const isFicheProduitPage = Boolean(productUri);
	const isSdpSearchPage = pathname.includes("sdp/search");
	const isSdpPageListing = pathname.includes("sdp/listing");
	const isSdpBookingQuotePage = pathname.includes("sdp/booking/quote");
	const isRootPage = pathname === "/";
	const isSdpRootPage = pathname === "/sdp" || isRootPage;
	const applyTransparentHeader = isTransparentHeader && (isSdpSearchPage || isSdpRootPage);
	const headerClass = classNames("header", {
		"header--sticky": isSdpPageListing,
		"header--transparent": applyTransparentHeader,
	});

	const isCurrentPathFallbackUrl = pathname === fallbackRedirectUrl;

	const isBrandEH = brand === BRANDS.EK;
	const logoClass = classNames("header__logo", {
		"header__logo--eh": isBrandEH,
		"header__logo--disabled": isCurrentPathFallbackUrl,
		"header__logo--eh-small": isBrandEH && isMobile && !(isSdpSearchPage || isSdpRootPage),
	});

	const isLogoWhite =
		brand === BRANDS.SE ||
		brand === BRANDS.CD ||
		(brand === BRANDS.TO && applyTransparentHeader);

	useOrangeHeader({ display: false });

	const isBrandXX = brand === BRANDS.XX;

	const isComingFromRemarketingEmail = checkIfComingFromRemarketingEmail(
		searchParams.get("utm_source"),
		searchParams.get("utm_medium")
	);

	const shouldRedirectToProductPage =
		isComingFromRemarketingEmail || searchParams.get("redirectToProductPage") === "true";

	// eslint-disable-next-line no-nested-ternary
	const fallbackUrlInProductPage = merchandisingCode
		? {
				pathname: "/merch",
				search: `?code=${merchandisingCode}`,
				merchCode: merchandisingCode,
		  }
		: shouldRedirectToProductPage
		? {
				pathname: `/${searchParams.get("productUri")}`,
		  }
		: {
				pathname: isBrandXX ? "/listing" : fallbackRedirectUrl,
		  };

	const inscriptionCreditCampaignAvailable =
		!isEmpty(inscriptionCreditCampaign) && displayable(inscriptionCreditCampaign);
	const shouldShowCreditItem =
		userIsConnected && (inscriptionCreditCampaignAvailable || creditsAmount > 0);

	return (
		<header className={headerClass}>
			{showBackBtn && (
				<div className="header__back">
					<BackButton
						{...(isFicheProduitPage || shouldRedirectToProductPage
							? { to: fallbackUrlInProductPage }
							: { forceBrowserBack: true })}
						onClick={onClickBackBtn}
					/>
				</div>
			)}

			<div className={logoClass}>
				{disabledHomeRedirect ? (
					<LogoBrandContainer isWhite={isLogoWhite} />
				) : (
					<RelativeLink
						to={{ pathname: fallbackRedirectUrl }}
						data-testid="header-back-link"
					>
						<LogoBrandContainer isWhite={isLogoWhite} />
					</RelativeLink>
				)}
			</div>

			<div className="header__content">
				{(isSdpBookingQuotePage || isSdpPageListing) && !isMobile && (
					<div className="header__smart-dp-form" data-testid="header-smart-dp-form">
						<AsyncSmartDPSearchFormContainer
							onSuccess={handleSDPFormSubmit}
							displayLoader={displayLoader}
						/>
					</div>
				)}
			</div>

			{showMenuItems && enableCredits && userIsConnected && shouldShowCreditItem && (
				<RelativeLink
					to={{ pathname: "/account/mycredits" }}
					className="header__credit"
					data-testid="header-credit-link"
				>
					<IconCreditHeader />
					<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} isBold>
						{creditsAmount > 0 ? (
							<AmountContainer amount={creditsAmount} />
						) : (
							<div className="header__credit-label">
								<FormattedMessage id="menu.item.my.credits" />
							</div>
						)}
					</Typography>
				</RelativeLink>
			)}

			{showShopSwitcher && isRootPage && (
				<ShopSwitcherContainer renderComponent={props => <ShopSwitcher {...props} />} />
			)}

			{showMenuItems && <AccountMenuContainer />}

			{isMobile && showDialPhone && dialPhone && (
				<div className="header__phone">
					<a
						className="header__phone-button"
						onClick={sendTagOnContactCall}
						href={`tel:${dialPhone}`}
						data-testid="header-phone"
					>
						<IconPhone height={30} width={30} />
					</a>
				</div>
			)}

			{operatorEmail && (
				<div className="header__operator">
					<strong>Agent :</strong> {operatorEmail}
				</div>
			)}
		</header>
	);
};

Header.propTypes = {
	brand: brandPropTypes,
	isMobile: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	enableCredits: PropTypes.bool,
	onClickBackBtn: PropTypes.func,
	handleSDPFormSubmit: PropTypes.func,
	previousUrl: PropTypes.string,
	fallbackRedirectUrl: PropTypes.string,
	operatorEmail: PropTypes.string,
	dialPhone: PropTypes.string,
	merchandisingCode: PropTypes.string,
	creditsAmount: PropTypes.number,
	showMenuItems: PropTypes.bool,
	showDialPhone: PropTypes.bool,
	displayLoader: PropTypes.bool,
	showBackBtn: PropTypes.bool,
	showShopSwitcher: PropTypes.bool,
	disabledHomeRedirect: PropTypes.bool,
	inscriptionCreditCampaign: PropTypes.object,
	isTransparentHeader: PropTypes.bool,
};

export default memo(Header);
